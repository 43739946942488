.noty_theme__nest.noty_bar {
  margin: 0 0 15px 0;
  overflow: hidden;
  border-radius: 2px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.098039) 5px 4px 10px 0; }
  .noty_theme__nest.noty_bar .noty_body {
    padding: 10px;
    font-size: 14px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); }
  .noty_theme__nest.noty_bar .noty_buttons {
    padding: 10px; }

.noty_layout .noty_theme__nest.noty_bar {
  z-index: 5; }

.noty_layout .noty_theme__nest.noty_bar:nth-child(2) {
  position: absolute;
  top: 0;
  margin-top: 4px;
  margin-right: -4px;
  margin-left: 4px;
  z-index: 4;
  width: 100%; }

.noty_layout .noty_theme__nest.noty_bar:nth-child(3) {
  position: absolute;
  top: 0;
  margin-top: 8px;
  margin-right: -8px;
  margin-left: 8px;
  z-index: 3;
  width: 100%; }

.noty_layout .noty_theme__nest.noty_bar:nth-child(4) {
  position: absolute;
  top: 0;
  margin-top: 12px;
  margin-right: -12px;
  margin-left: 12px;
  z-index: 2;
  width: 100%; }

.noty_layout .noty_theme__nest.noty_bar:nth-child(5) {
  position: absolute;
  top: 0;
  margin-top: 16px;
  margin-right: -16px;
  margin-left: 16px;
  z-index: 1;
  width: 100%; }

.noty_layout .noty_theme__nest.noty_bar:nth-child(n+6) {
  position: absolute;
  top: 0;
  margin-top: 20px;
  margin-right: -20px;
  margin-left: 20px;
  z-index: -1;
  width: 100%; }

#noty_layout__bottomLeft .noty_theme__nest.noty_bar:nth-child(2),
#noty_layout__topLeft .noty_theme__nest.noty_bar:nth-child(2) {
  margin-top: 4px;
  margin-left: -4px;
  margin-right: 4px; }

#noty_layout__bottomLeft .noty_theme__nest.noty_bar:nth-child(3),
#noty_layout__topLeft .noty_theme__nest.noty_bar:nth-child(3) {
  margin-top: 8px;
  margin-left: -8px;
  margin-right: 8px; }

#noty_layout__bottomLeft .noty_theme__nest.noty_bar:nth-child(4),
#noty_layout__topLeft .noty_theme__nest.noty_bar:nth-child(4) {
  margin-top: 12px;
  margin-left: -12px;
  margin-right: 12px; }

#noty_layout__bottomLeft .noty_theme__nest.noty_bar:nth-child(5),
#noty_layout__topLeft .noty_theme__nest.noty_bar:nth-child(5) {
  margin-top: 16px;
  margin-left: -16px;
  margin-right: 16px; }

#noty_layout__bottomLeft .noty_theme__nest.noty_bar:nth-child(n+6),
#noty_layout__topLeft .noty_theme__nest.noty_bar:nth-child(n+6) {
  margin-top: 20px;
  margin-left: -20px;
  margin-right: 20px; }

.noty_theme__nest.noty_type__alert,
.noty_theme__nest.noty_type__notification {
  background-color: #073B4C;
  color: #fff; }
  .noty_theme__nest.noty_type__alert .noty_progressbar,
  .noty_theme__nest.noty_type__notification .noty_progressbar {
    background-color: #fff; }

.noty_theme__nest.noty_type__warning {
  background-color: #FFD166;
  color: #fff; }

.noty_theme__nest.noty_type__error {
  background-color: #EF476F;
  color: #fff; }
  .noty_theme__nest.noty_type__error .noty_progressbar {
    opacity: .4; }

.noty_theme__nest.noty_type__info,
.noty_theme__nest.noty_type__information {
  background-color: #118AB2;
  color: #fff; }
  .noty_theme__nest.noty_type__info .noty_progressbar,
  .noty_theme__nest.noty_type__information .noty_progressbar {
    opacity: .6; }

.noty_theme__nest.noty_type__success {
  background-color: #06D6A0;
  color: #fff; }
