/**
 * jQuery cxCalendar Style
 * ------------------------------ */
.cxcalendar{display:none;position:absolute;width:250px;border:1px solid #ccd1d9;border-radius:3px;background:#fff;box-shadow:1px 2px 3px rgba(0,0,0,0.2);color:#434a54;font-size:14px;z-index:10000;}
.cxcalendar_hd{position:relative;padding:6px 0 0;}

/* 上下月切换按钮 */
.cxcalendar_hd .prev,
.cxcalendar_hd .next{position:absolute;top:50%;width:28px;height:28px;padding:0;margin-top:-12px;border:1px solid transparent;border-radius:2px;color:#333;font:0/0 Arial;text-decoration:none;outline:0;}
.cxcalendar_hd .prev{left:25px;margin-left:-12px;}
.cxcalendar_hd .next{right:25px;margin-right:-12px;}
.cxcalendar_hd .prev:before,
.cxcalendar_hd .next:before{content:'';position:absolute;top:50%;left:50%;width:0;height:0;margin:-6px 0 0 -5px;border-top:6px solid transparent;border-bottom:6px solid transparent;font:0/0 Arial;}
.cxcalendar_hd .prev:before{border-right:10px solid #333;}
.cxcalendar_hd .next:before{border-left:10px solid #333;}

/* 年月文本 */
.cxcalendar_hd .intxt{margin:0 50px;border:1px solid transparent;border-radius:3px;font-weight:bold;font-size:16px;line-height:28px;text-align:center;cursor:pointer;}
.cxcalendar_hd .intxt .y:after{content:'年';margin:0 0.5em;}
.cxcalendar_hd .intxt .m:after{content:'月';margin-left:0.5em;}

.cxcalendar_hd .prev:hover,
.cxcalendar_hd .next:hover,
.cxcalendar_hd .intxt:hover{border:1px solid #aab2bd;background:#e6e9ed;}

/* 年月选框 */
.cxcalendar_hd .inset{display:none;margin:0 50px;text-align:center;}
.cxcalendar_hd .inset:after{content:'';visibility:hidden;display:block;clear:both;height:0;font-size:0;}
.cxcalendar_hd .inset select{box-sizing:border-box;float:left;width:45%;height:30px;margin:0;padding:0;border:1px solid #aab2bd;border-radius:3px;background:#fff;font-size:14px;line-height:28px;text-align:center;}
.cxcalendar_hd .inset .month{float:right;}

/* 日期 */
.cxcalendar_bd{position:relative;padding:0 0 4px 0;line-height:30px;text-align:center;z-index:1;
*line-height:28px;
}
.cxcalendar_bd ul{overflow:hidden;margin:0;padding:0;list-style:none;}
.cxcalendar_bd ul li{box-sizing:border-box;float:left;width:14%;height:34px;margin:0;padding:0;border:2px solid #fff;border-radius:5px;
*border:none;
}
.cxcalendar_bd ul li:nth-child(7n+1){margin-left:1%;}

/* 日期标题 */
.cxcalendar_bd .week{margin-bottom:4px;color:#888;}

/* 日期列表 */
.cxcalendar_bd .days{color:#656d78;}
.cxcalendar_bd .days li{cursor:pointer;}
.cxcalendar_bd .days li:hover{background:#e6e9ed;}
.cxcalendar_bd .days .sat{color:#4a89dc}
.cxcalendar_bd .days .sun{color:#da4453;}
.cxcalendar_bd .days .now{background:#f3f3f3;}
.cxcalendar_bd .days .holiday{color:#da4453;}
.cxcalendar_bd .days .other{color:#ccd1d9;}
.cxcalendar_bd .days .del{color:#ccd1d9;text-decoration:line-through;cursor:default;}
.cxcalendar_bd .days .selected,
.cxcalendar_bd .days .selected:hover{background:#8cc152;color:#fff;}

/* 时间 */
.cxcalendar .settime{overflow:hidden;position:relative;padding:6px;border-top:1px solid #ddd;}
.cxcalendar .settime input{width:2em;height:26px;margin:0;padding:0;border:none;border-top:1px solid #fff;border-bottom:1px solid #ddd;background:none;color:#656d78;font-size:14px;line-height:24px;text-align:center;outline:none;vertical-align:top;}
.cxcalendar .settime .day{float:left;width:6em;height:26px;border-top:1px solid #fff;border-bottom:1px solid #ddd;color:#656d78;line-height:26px;text-align:center;}
.cxcalendar .settime .day:empty:before{content:'请选择日期';color:#aab2bd;}

.cxcalendar .settime .time{float:left;margin-left:5px;font-size:0;line-height:26px;}
.cxcalendar .settime .time i{color:#aab2bd;font-style:normal;font-size:14px;vertical-align:top;}

.cxcalendar .settime .confirm{float:right;height:28px;padding:0 10px;border-radius:3px;background-color:#4a89dc;color:#fff;line-height:28px;text-decoration:none;}
.cxcalendar .settime .confirm:before{content:'确定';}
.cxcalendar .settime .confirm:hover{background-color:#3d7aca;}

/* 其他操作，若不需要可以设为 display:none; */
.cxcalendar .acts{position:absolute;top:70px;left:100%;width:2em;}
.cxcalendar .acts a{display:block;width:1em;margin-bottom:10px;padding:5px 0.5em;border-radius:0 3px 3px 0;background-color:#ccd1d9;box-shadow:1px 1px 2px rgba(0,0,0,0.2);color:#fff;line-height:1.2;text-decoration:none;text-align:center;}
.cxcalendar .acts a:hover{background-color:#aab2bd;}
.cxcalendar .acts .today:before{content:'今天';}
.cxcalendar .acts .clear:before{content:'清除';}

/* 背景遮罩层 */
.cxcalendar_lock{display:none;position:fixed;top:0;left:0;width:100%;height:100%;background:#fff;z-index:9999;filter:alpha(opacity=0);opacity:0;}


/* 不显示秒输入框 */
.cxcalendar_notsecs .cxcalendar .settime .time .mint + i,
.cxcalendar_notsecs .cxcalendar .settime .time .secs{display:none;}

/* 英文 */
.cxcalendar_en .cxcalendar_hd .intxt .y:after{content:'-';margin:0 6px;}
.cxcalendar_en .cxcalendar_hd .intxt .m:after{display:none;content:'';}

/* 节假日 */
.cxcalendar_holyday{width:460px;}
.cxcalendar_holyday .cxcalendar_bd{font-size:20px;line-height:56px;}
.cxcalendar_holyday .cxcalendar_bd ul li{height:60px;}
.cxcalendar_holyday .cxcalendar_bd .days .holiday{position:relative;}
.cxcalendar_holyday .cxcalendar_bd .days .holiday:before{content:attr(title);position:absolute;top:50%;left:0;overflow:hidden;width:100%;margin-top:12px;font-size:12px;line-height:1;text-align:center;white-space:nowrap;text-overflow:ellipsis;}