/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/noty@3.2.0-beta/lib/noty.css
 * - /npm/noty@3.2.0-beta/lib/themes/nest.css
 * - /npm/qtip2@3.0.3/dist/jquery.qtip.css
 * - /npm/select2@4.0.6-rc.1/dist/css/select2.min.css
 * - /npm/selectize@0.12.6/dist/css/selectize.css
 * - /npm/swiper@4.4.2/dist/css/swiper.min.css
 * - /npm/@chenfengyuan/datepicker@1.0.7/dist/datepicker.min.css
 * - /gh/ppyupp/cxCalendar@h2/css/jquery.cxcalendar.css
 * - /npm/daterangepicker@3.0.3/daterangepicker.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
